import api from '../../utils/api'

// 授权
export async function get_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data:{
  //         result_date_time: new Date(),
  //         comb: '血常规',
  //         laboratories: [
  //           {
  //             laboratory_id: 1,
  //             laboratory_name: '化验名称',
  //             standard_units: 'ml',
  //             standard_result: '阳性',
  //             standard_result_type: 1,
  //             standard_specimen: '标本',
  //             abnormal: true,
  //             standard_reference_interval:'[1.015~1.025]'
  //           },
  //           {
  //             laboratory_id: 2,
  //             laboratory_name: '化验名称',
  //             standard_units: 'ml',
  //             standard_result: '叙述性结果德国海地共和国搭建国际化的更好的孤独感回家的感觉的广东省更好的时光会打电话感动好感动好感动好',
  //             standard_result_type: 3,
  //             standard_specimen: '标本',
  //             abnormal: true,
  //             standard_reference_interval:'[1.015~1.025]',
  //           },
  //           {
  //             laboratory_id: 3,
  //             laboratory_name: '化验名称',
  //             standard_units: 'ml',
  //             standard_result: '123',
  //             standard_result_type: 2,
  //             standard_specimen: '标本',
  //             abnormal: true,
  //             standard_reference_interval:'[1.015~1.025]',
  //             standard_normalized_quantitative: 12
  //           }
  //         ],
  //         id: 11,
  //         create_mini_user_id: 1223,
  //       }
  //     })
  //   }, 1000)
  // })
  return api.get(`/api/v1/mp/laboratories/details`,{params: payload})
}
