<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="content g_main_content">
      <div class="title">{{data&&data.comb}}</div>
      <div class="title1">{{data&&format_date(data.result_time,'yyyy年MM月dd日')}}</div>
      <div v-for="(item,index) in laboratories" :key="index" class="abnomal-i"  @click="jup_laboratory(item)">
        <div class="abnomal-name">
          <span style="flex:1">{{item.laboratory_name}}
            <span v-if="item.standard_result_type===2">{{item.abnormal ? (item.standard_normalized_quantitative>1 ? '升高' : '降低') : '正常'}}</span>
            <span v-else class="specimen">{{ item.standard_specimen}}</span>
            <span v-if="item.standard_result_type===1" :class="['result',{'abnormal':item.abnormal }]" >{{ item.standard_result}}</span>
          </span>
          <img width="10" src="../../assets/img/report-arrow.png"/>
        </div>
        <ul class="indicators" v-if="item.standard_result_type>1">
          <template v-if="item.standard_result_type===2">
            <li>
              标本
              <div> {{item.standard_specimen}}</div>
            </li>
            <li>
              结果
              <div class="abnormal-info">
                <span :class="{'abnormal': !item.standard_normalized_quantitative}">{{ item.standard_result}}</span>
                <img width="14" height="14" v-if="item.standard_normalized_quantitative > 1" src="../../assets/img/up.png"/>
                <img width="14" height="14" v-else-if="item.standard_normalized_quantitative < 0" src="../../assets/img/down.png"/>
              </div>
            </li >
              <li class="refer">
              参考值
              <div> {{item.standard_reference_interval}}</div>
            </li>
              <li>
              单位
              <div> {{item.standard_units}}</div>
            </li>
          </template>
          <li class="narrative" v-else>
              叙述结果
              <div> {{item.standard_result}}</div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import {format_date} from '../../utils/format'
import {get_list} from './service'
export default {
  data() {
    return {
      loading: false,
      data: null,
      t_arr: []
    }
  },
  computed:{
    laboratories() {
      let arr = []
      let laboratories = this.data&&this.data.laboratories || []
      arr = laboratories.filter(i=>{
        return i.standard_result
      })
      return arr
    }
  },
  created() {
    this.search_list()
  },
  methods: {
    format_date,
    jup_laboratory(i){
      this.$router.push({
        path: '/laboratory-detail',
        query: {
          name: i.laboratory_name,
          specimen: i.standard_specimen
        }
      })
    },
    async search_list () {
      let {order_id,laboratory_id} = this.$route.query
      try {
        this.loading = true
        const {data} = await get_list({order_id,laboratory_id})
        this.data = data
      } finally {
        this.loading =  false
      }
    }
  }
}
</script>
<style scoped>
  .content {
    padding: 34px 16px;
  }
  .abnomal-i {
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    padding: 10px 12px;
    margin-bottom: 20px;
    & .abnomal-name {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #242938;
      & .specimen {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #242938;
        padding: 0 10px;
      }
      & .result {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        & .abnormal {
          color: #FA5C7C;
        }
      }
    }
    & .abnormal-info {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 18px;
      color: #242938;
      & img {
        margin-left: 2px;
      }
      & .abnormal {
        color: #FA5C7C;
      }
    }
    & .indicators {
      margin-top: 10px;
      display: flex;
      padding: 10px 0;
      min-height: 55px;
      background: #F4F9FE;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #242938;
      & li {
        flex: 1;
        text-align: center;
        &.refer {
          flex:1.5;
        }
        & >div {
          padding-top: 2px;
        }
      }
      & .narrative {
        padding: 0 10px;
        text-align: left;
        line-height: 18px;
      }
    }
  }
  .title1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #8B959E;
    padding:3px 18px 20px;

  }
  .title {
    font-size: 16px;
    color: #242938;
    margin-top: 35px;
    position: relative;
    margin:0 16px 0;
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: -14px;
      height: 16px;
      width: 6px;
      border-radius: 6px;
      background: #0088FF;
    }
  }
</style>
